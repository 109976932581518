import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Tax Audit & Attestation Services | DC, MD & VA | DeBlanc"
        description="Get a full range of tax audit and attestation services. Call DeBlanc + Murphy to learn more and schedule a time. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Financial Audit & Attestation Services</h1>
            <p className="mb-0 md:text-xl">
              We offer a full range of audits and financial reporting that you
              can provide to management, investors, lending institutions,
              sureties, or government entities. Serving the greater Washington,
              DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Our Services</h2>
                <p>
                  We provide three types of financial reporting—an audit,
                  review, or compilation. Each represents a different level of
                  assurance on the propriety of the financial statements in
                  compliance with the Statements on Standards for Accounting and
                  Review Services issued by the American Institute of Certified Public Accountants.
                </p>
              </div>

              <div className="mb-16">
                <h3>Financial Audit</h3>
                <p>
                  A financial audit is an intensive examination of your
                  company’s systems and records and provides the highest level
                  of assurance. We examine your records and financial statements
                  closely so we can express an opinion on whether the financial
                  statements fairly present your company’s financial position.
                  We do this in accordance with generally accepted accounting
                  principles.
                </p>
                <p>
                We’ve audited firms of all sizes, including small and medium sized businesses with revenue ranging from less than one million to over hundreds of millions. Our team is also experienced in governmental audits (Yellow Book audits), non-profit audits (Single audits/A-133 audits), educational institutions, forensic audits, employee benefit plan audits, and retirement plan audits.
                </p>
                <p>
                  Each audit includes a management advisory letter that’s
                  written in layman’s terms. It includes interpretations of the
                  financial statements (if necessary), along with any
                  suggestions we have, such as recommendations for improving
                  internal controls. We meet with your management personally to
                  present our report and answer questions.
                </p>
              </div>

              <div className="mb-16">
                <h3>Review</h3>
                <p>
                  A review provides limited assurance that the financial
                  statements are fairly presented in accordance with generally
                  accepted accounting principles. We make inquiries concerning
                  financial, operating and contractual information, apply
                  analytical procedures, and hold discussions with appropriate
                  officials.
                </p>
                <p>
                  A review can be used by management or third parties, and is
                  generally considered more beneficial than a compilation
                  service, but not as beneficial as an audit.
                </p>
              </div>

              <div className="mb-16">
                <h3>Compilation</h3>
                <p>
                  A compilation consists of financial statements prepared by an
                  accountant that is outside of the company. Simply put, you
                  provide us with your data and we prepare your financial
                  statements without any auditing or review.
                </p>
                
              </div>

              <div className="mb-16">
                <h3>Agreed-Upon Procedures</h3>
                <p>
                Maybe you don’t need an audit, review, or compilation. Perhaps you just want a third-party examination of specific business processes or procedures and a report on the factual findings. Agreed-Upon Procedures do just that.
                </p>
              </div>

              <div className="mb-16">
                <h3>Forensic Audit</h3>
                <p>
                  If you suspect illegal financial activity in your business,
                  such as fraud or embezzlement, a forensic audit can help
                  uncover it and provide you with evidence that can potentially
                  be used in court.
                </p>
                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>

              
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={5} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={5} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "2.3 Audit and Attestation/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
